import { InternalAgent } from 'api/auth/types';
import { TagsType } from 'api/tags';
import EvidenceFilesService, {
  EvidenceFilesServiceInterface,
} from 'commons/services/EvidenceFilesService';
import { Config } from 'config/duck';
import TaoArticleSearchService, {
  TaoArticleSearchServiceInterface,
} from 'tao/Marches/Article/table/ArticleSearchService';
import TaoProductSearchService, {
  TaoProductSearchServiceInterface,
} from 'tao/Marches/Product/table/ProductSearchService';
import TaoProductCreateService, {
  TaoProductCreateServiceInterface,
} from 'tao/Marches/Product/creation/ProductCreateService';
import TaoProductFetchService, {
  TaoProductFetchServiceInterface,
} from 'tao/Marches/Product/detail/ProductFetchService';
import TaoProductUpdateService, {
  TaoProductUpdateServiceInterface,
} from 'tao/Marches/Product/edition/ProductUpdateService';

import TaoSubscriberCreateService, {
  TaoSubscriberCreateServiceInterface,
} from '../../tao/Subscriber/creation/TaoSubscriberCreateService';
import TaoSubscriberFetchService, {
  TaoSubscriberFetchServiceInterface,
} from '../../tao/Subscriber/detail/TaoSubscriberFetchService';
import TaoSubscriberUpdateService, {
  TaoSubscriberUpdateServiceInterface,
} from '../../tao/Subscriber/edition/TaoSubscriberUpdateService';
import TaoSubscriberSearchService, {
  TaoSubscriberSearchServiceInterface,
} from '../../tao/Subscriber/table/TaoSubscriberSearchService';
import ArticleConfigApiService, {
  ArticleConfigApiServiceInterface,
} from '../../tao/Marches/Article/detail/ArticleConfigApiService';
import ArticleConfigEditionService, {
  ArticleConfigEditionServiceInterface,
} from '../../tao/Marches/Article/detail/ArticleConfigEditionService';

import AddressMapService, {
  AddressMapServiceInterface,
} from './AddressMapService';
import CcspListService, { CcspListServiceInterface } from './CcspListService';
import CityConfigService, {
  CityConfigServiceInterface,
} from './CityConfigService';
import CommentService, { CommentServiceInterface } from './CommentService';
import ComplementRequestService, {
  ComplementRequestServiceInterface,
} from './ComplementRequestService';
import ControlListService, {
  ControlListServiceInterface,
} from './ControlListService';
import ControlService, { ControlServiceInterface } from './ControlService';
import DecisionPriceService, {
  DecisionPriceServiceInterface,
} from './DecisionPriceService';
import EligibilityRightService, {
  EligibilityRightServiceInterface,
} from './EligibilityRightService';
import EligibilityService, {
  EligibilityServiceInterface,
} from './EligibilityService';
import FpsListService, { FpsListServiceInterface } from './FpsListService';
import FpsPaymentsService, {
  FpsPaymentsServiceInterface,
} from './FpsPaymentsService';
import FpsService, { FpsServiceInterface } from './FpsService';
import GeocodingApiService, {
  GeocodingApiServiceInterface,
} from './GeocodingService';
import LapiReviewService, {
  LapiReviewServiceInterface,
} from './LapiReviewService';
import MailTemplateService, {
  MailTemplateServiceInterface,
} from './MailTemplateService';
import MapService, { MapServiceInterface } from './MapService';
import ParkingRightsService, {
  ParkingRightsServiceInterface,
} from './ParkingRightService';
import ParkingSpaceApiService, {
  ParkingSpaceServiceApiInterface,
} from './ParkingSpace/ParkingSpaceApiService';
import ParkingSpaceChangeRegimeService, {
  ParkingSpaceChangeRegimeServiceInterface,
} from './ParkingSpace/ParkingSpaceChangeRegimeService';
import ParkingSpaceCreationService, {
  ParkingSpaceCreationServiceInterface,
} from './ParkingSpace/ParkingSpaceCreationService';
import ParkingSpaceDeletionService, {
  ParkingSpaceDeletionServiceInterface,
} from './ParkingSpace/ParkingSpaceDeletionService';
import ParkingSpaceDuplicationService, {
  ParkingSpaceDuplicationServiceInterface,
} from './ParkingSpace/ParkingSpaceDuplicationService';
import ParkingSpaceEditionService, {
  ParkingSpaceEditionServiceInterface,
} from './ParkingSpace/ParkingSpaceEditionService';
import ParkingSpaceMapService, {
  ParkingSpaceServiceMapInterface,
} from './ParkingSpace/ParkingSpaceMapService';
import ParkingSpaceModificationService, {
  ParkingSpaceModificationServiceInterface,
} from './ParkingSpace/ParkingSpaceModificationService';
import PatrolZoneService, {
  PatrolZoneServiceInterface,
} from './PatrolZoneService';
import ProductService, { ProductServiceInterface } from './ProductService';
import ProposalService, { ProposalServiceInterface } from './ProposalService';
import RecourseConfigurationService, {
  RecourseConfigurationServiceInterface,
} from './RecourseConfigurationService';
import RecourseListService, {
  RecourseListServiceInterface,
} from './RecourseListService';
import RecourseReasonsService, {
  RecourseReasonsServiceInterface,
} from './RecourseReasonsService';
import RecourseService, { RecourseServiceInterface } from './RecourseService';
import TagsService, { TagsServiceInterface } from './TagsService';
import TicketsListService, {
  TicketsListServiceInterface,
} from './TicketsListService';
import UserInfoService, { UserInfoServiceInterface } from './UserInfoService';
import ZoningService, { ZoningServiceInterface } from './ZoningService';
import ParkingDetailService, {
  ParkingDetailServiceInterface,
} from './OrderPricingService';

interface ServicesInterface {
  init: (
    config: Config,
    userInfo: InternalAgent,
    type: TagsType
  ) => Promise<void>;
  addressMap: AddressMapServiceInterface;
  ccspList: CcspListServiceInterface;
  cityConfig: CityConfigServiceInterface;
  comment: CommentServiceInterface;
  complementRequest: ComplementRequestServiceInterface;
  control: ControlServiceInterface;
  controls: ControlListServiceInterface;
  decisionPrice: DecisionPriceServiceInterface;
  eligibilities: EligibilityServiceInterface;
  eligibilityRights: EligibilityRightServiceInterface;
  evidenceFiles: EvidenceFilesServiceInterface;
  fps: FpsServiceInterface;
  fpsList: FpsListServiceInterface;
  fpsPayments: FpsPaymentsServiceInterface;
  geocodingApi: GeocodingApiServiceInterface;
  lapiReview: LapiReviewServiceInterface;
  mailTemplate: MailTemplateServiceInterface;
  mapService: MapServiceInterface;
  patrolZoneService: PatrolZoneServiceInterface;
  parkingRights: ParkingRightsServiceInterface;
  parkingSpaceApi: ParkingSpaceServiceApiInterface;
  parkingSpaceMap: ParkingSpaceServiceMapInterface;
  parkingSpaceCreation: ParkingSpaceCreationServiceInterface;
  parkingSpaceDuplication: ParkingSpaceDuplicationServiceInterface;
  parkingSpaceDeletion: ParkingSpaceDeletionServiceInterface;
  parkingSpaceEdition: ParkingSpaceEditionServiceInterface;
  parkingSpaceChangeRegime: ParkingSpaceChangeRegimeServiceInterface;
  parkingSpaceModification: ParkingSpaceModificationServiceInterface;
  products: ProductServiceInterface;
  proposal: ProposalServiceInterface;
  recourse: RecourseServiceInterface;
  recourseListService: RecourseListServiceInterface;
  recourseReasons: RecourseReasonsServiceInterface;
  recourseConfiguration: RecourseConfigurationServiceInterface;
  taoSubscriberFetch: TaoSubscriberFetchServiceInterface;
  taoSubscriberCreate: TaoSubscriberCreateServiceInterface;
  taoSubscriberUpdate: TaoSubscriberUpdateServiceInterface;
  taoSubscriberSearch: TaoSubscriberSearchServiceInterface;
  taoProductSearchService: TaoProductSearchServiceInterface;
  taoProductCreateService: TaoProductCreateServiceInterface;
  taoProductFetchService: TaoProductFetchServiceInterface;
  taoProductUpdateService: TaoProductUpdateServiceInterface;
  taoArticleSearchService: TaoArticleSearchServiceInterface;
  taoArticleConfigApiService: ArticleConfigApiServiceInterface;
  taoArticleConfigEditionService: ArticleConfigEditionServiceInterface;
  parkingDetailService: ParkingDetailServiceInterface;
  tags: TagsServiceInterface;
  userInfo: UserInfoServiceInterface;
  zoning: ZoningServiceInterface;
  ticketsList: TicketsListServiceInterface;
}

const Services = (): ServicesInterface => {
  const cityConfig = CityConfigService();
  const userInfo = UserInfoService();
  const recourse = RecourseService();
  const mailTemplate = MailTemplateService(userInfo, recourse);
  const mapService = MapService();
  const complementRequest = ComplementRequestService();
  const tags = TagsService(userInfo);
  const comment = CommentService();
  const control = ControlService();
  const controls = ControlListService();
  const zoning = ZoningService();
  const recourseReasons = RecourseReasonsService(recourse);
  const proposal = ProposalService(recourse);
  const decisionPrice = DecisionPriceService(recourse);
  const fps = FpsService();
  const patrolZoneService = PatrolZoneService();
  const parkingRights = ParkingRightsService();
  const parkingSpaceApi = ParkingSpaceApiService();
  const parkingSpaceCreation = ParkingSpaceCreationService();
  const parkingSpaceDuplication = ParkingSpaceDuplicationService(
    parkingSpaceCreation
  );
  const parkingSpaceModification = ParkingSpaceModificationService();
  const parkingSpaceNeutralization = ParkingSpaceChangeRegimeService(
    parkingSpaceApi
  );
  const parkingSpaceMap = ParkingSpaceMapService(
    parkingSpaceApi,
    parkingSpaceNeutralization,
    mapService
  );
  const parkingSpaceEdition = ParkingSpaceEditionService(
    parkingSpaceApi,
    parkingSpaceMap
  );
  const parkingSpaceDeletion = ParkingSpaceDeletionService(parkingSpaceApi);

  const eligibilities = EligibilityService();
  const commentFiles = EvidenceFilesService();
  const fpsPayments = FpsPaymentsService(fps);
  const products = ProductService();
  const eligibilityRights = EligibilityRightService();
  const recourseListService = RecourseListService();
  const recourseConfiguration = RecourseConfigurationService();
  const lapiReview = LapiReviewService();
  const geocodingApi = GeocodingApiService();
  const addressMap = AddressMapService(mapService);
  const taoSubscriberFetch = TaoSubscriberFetchService();
  const taoSubscriberCreate = TaoSubscriberCreateService();
  const taoSubscriberUpdate = TaoSubscriberUpdateService();
  const taoSubscriberSearch = TaoSubscriberSearchService();
  const taoProductSearchService = TaoProductSearchService();
  const taoArticleSearchService = TaoArticleSearchService();
  const taoArticleConfigApiService = ArticleConfigApiService();
  const taoArticleConfigEditionService = ArticleConfigEditionService(
    taoArticleConfigApiService
  );
  const fpsList = FpsListService();
  const ccspList = CcspListService();
  const ticketsList = TicketsListService();
  const taoProductCreateService = TaoProductCreateService();
  const taoProductFetchService = TaoProductFetchService();
  const taoProductUpdateService = TaoProductUpdateService();
  const parkingDetailService = ParkingDetailService();

  const init = async (
    config: Config,
    user: InternalAgent,
    type: TagsType
  ): Promise<void> => {
    cityConfig.init(config);
    userInfo.init(user);
    await tags.init(type);
    await recourseConfiguration.init();
  };

  return {
    addressMap,
    ccspList,
    cityConfig,
    comment,
    complementRequest,
    control,
    controls,
    decisionPrice,
    eligibilities,
    eligibilityRights,
    evidenceFiles: commentFiles,
    fps,
    fpsList,
    fpsPayments,
    geocodingApi,
    init,
    lapiReview,
    mailTemplate,
    mapService,
    patrolZoneService,
    parkingRights,
    parkingSpaceApi,
    parkingSpaceMap,
    parkingSpaceCreation,
    parkingSpaceDuplication,
    parkingSpaceEdition,
    parkingSpaceModification,
    parkingSpaceChangeRegime: parkingSpaceNeutralization,
    parkingSpaceDeletion,
    products,
    proposal,
    recourse,
    recourseListService,
    recourseReasons,
    recourseConfiguration,
    taoSubscriberFetch,
    taoSubscriberCreate,
    taoSubscriberUpdate,
    taoSubscriberSearch,
    taoProductSearchService,
    taoProductCreateService,
    taoProductFetchService,
    taoProductUpdateService,
    taoArticleSearchService,
    taoArticleConfigApiService,
    taoArticleConfigEditionService,
    parkingDetailService,
    tags,
    userInfo,
    zoning,
    ticketsList,
  };
};

export default Services();
