import services from 'commons/services';
import useWatcher from 'commons/hooks/useWatcher';
import { SortParameters } from 'api/commonTypes';

const useRecourseSort = (): [
  SortParameters,
  (arg0: SortParameters) => void
] => {
  return [
    useWatcher(services.recourseListService.watchRecourseSort, {
      sortField: 3,
      increasingOrder: true,
    }),
    services.recourseListService.updateSortParameter,
  ];
};

export default useRecourseSort;
