import {
  OrderPrivateDTO,
  SubscriberDTO,
  SubscriberVehicle,
  VehicleCategory,
} from '@cvfm-front/tefps-types';
import { AuthorizedVehicleCategory } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

const vehicleTypeLabels = [
  { value: 'CAR' as VehicleCategory, label: _tg('field.vehicleType.CAR') },
  {
    value: 'ELECTRIC_CAR' as VehicleCategory,
    label: _tg('field.vehicleType.ELECTRIC_CAR'),
  },
  {
    value: 'HEAVY_CAR' as VehicleCategory,
    label: _tg('field.vehicleType.HEAVY_CAR'),
  },
  {
    value: 'MOTORCYCLE' as VehicleCategory,
    label: _tg('field.vehicleType.MOTORCYCLE'),
  },
  {
    value: 'CAMPING_CAR' as VehicleCategory,
    label: _tg('field.vehicleType.CAMPING_CAR'),
  },
  {
    value: 'TRUCK' as VehicleCategory,
    label: _tg('field.vehicleType.TRUCK'),
  },
  { value: 'BUS' as VehicleCategory, label: _tg('field.vehicleType.BUS') },
  {
    value: 'COLLECTION' as VehicleCategory,
    label: _tg('field.vehicleType.COLLECTION'),
  },
  {
    value: 'OLD' as VehicleCategory,
    label: _tg('field.vehicleType.OLD'),
  },
  {
    value: 'SPECIALIZED' as VehicleCategory,
    label: _tg('field.vehicleType.SPECIALIZED'),
  },
  {
    value: 'ISOTHERM' as VehicleCategory,
    label: _tg('field.vehicleType.ISOTHERM'),
  },
];

export function getLabelFromVehicleType(vehicleType: VehicleCategory): string {
  return (
    vehicleTypeLabels.find(({ value }) => value === vehicleType)?.label || ''
  );
}

export const translateVehicleTypeFilterOptions = (
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>
): Array<{
  value: VehicleCategory;
  label: string;
}> =>
  vehicleTypeLabels.filter(v =>
    authorizedVehicleCategories.some(
      authorized => authorized.vehicleCategory === v.value
    )
  );

export const getOrderVehiclesCategories = (
  order: OrderPrivateDTO,
  subscribers: SubscriberDTO[]
): Set<VehicleCategory> => {
  const vehiclesCategories: Set<VehicleCategory> = new Set();

  const { plates } = order.plateHistory[order.plateHistory.length - 1];

  // flatMap all vehicles of a profile
  const subscribersVehicles: SubscriberVehicle[] = (order.createdForProAccount
    ? subscribers
        .filter(subscriber => subscriber.professionalProfile !== undefined)
        .map(subscriber => subscriber.professionalProfile?.vehicles || [])
    : subscribers
        .filter(subscriber => subscriber.personalProfile)
        .map(subscriber => subscriber.personalProfile?.vehicles || [])
  ).reduce((acc, vehicles) => acc.concat(vehicles), []);

  plates.forEach(plate => {
    const plateCorrespondingVehicle:
      | SubscriberVehicle
      | undefined = subscribersVehicles.find(
      vehicle => vehicle.plate === plate
    );
    if (plateCorrespondingVehicle) {
      vehiclesCategories.add(plateCorrespondingVehicle.vehicleCategory);
    }
  });

  return vehiclesCategories;
};
