import _debounce from 'lodash.debounce';

import { Pager } from '@cvfm-front/tefps-types';
import { FpsSearchResultDTO } from 'api/fps/types';
import { Watcher } from '@cvfm-front/commons-utils';
import { WatchFunctionType } from '@cvfm-front/commons-types';
import { fetchFpsFilteredList } from 'api/fps';
import { filtersToRequest, initialFilters } from 'tefps/Fps/List/utils';
import { FpsSearchCriterias } from 'tefps/Fps/List/types';
import { SortParameters } from 'api/commonTypes';

import loadQueryParamFilters from '../helpers/loadQueryParamFilters';
import { DEFAULT_FILTER_DEBOUNCE } from 'commons/const';

export interface FpsListServiceFactory {
  (): FpsListServiceInterface;
}

export interface FpsListServiceInterface {
  init: (locationSearch: string | null) => Promise<void>;
  reload: () => Promise<void>;

  getFilters: () => FpsSearchCriterias;
  watchFilters: WatchFunctionType<FpsSearchCriterias>;
  updateFilters: (filters: FpsSearchCriterias) => Promise<void>;

  updateSortParameter: (sortField: number) => Promise<void>;
  getSortParameter: () => SortParameters;
  watchSortParameter: WatchFunctionType<SortParameters>;

  getFpses: () => FpsSearchResultDTO | null;
  watchFpses: WatchFunctionType<FpsSearchResultDTO | null>;

  getError: () => Error | null;
  watchError: WatchFunctionType<Error | null>;
  reportError: (error: Error) => void;

  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;

  loadMoreRows: () => Promise<void>;
}

const MAX_RECORDS = 20;
const INITIAL_PAGE = 0;
const DEFAULT_SORT = false;
export const DEFAULT_PAGER: Pager = {
  page: INITIAL_PAGE,
  maxRecords: MAX_RECORDS,
};

const FpsListService: FpsListServiceFactory = () => {
  const {
    getValue: getFpses,
    setValue: setFpses,
    watchValue: watchFpses,
  } = Watcher<FpsSearchResultDTO | null>(null);

  const {
    getValue: getFilters,
    setValue: setFilters,
    watchValue: watchFilters,
  } = Watcher<FpsSearchCriterias>(initialFilters());

  const {
    getValue: getError,
    setValue: setError,
    watchValue: watchError,
  } = Watcher<Error | null>(null);

  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<SortParameters>({
    sortField: 2,
    increasingOrder: DEFAULT_SORT,
  });

  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  let currentPage = INITIAL_PAGE;
  let pager: Pager = {
    page: currentPage,
    maxRecords: MAX_RECORDS,
  };

  /**
   * Fonction pour report une erreur sur les loading
   */
  const reportError = (err: Error): void => {
    setError(err);
    setLoading(false);
    setFpses(null);
  };

  /**
   * Charge une page de FPS
   */
  const loadFpsList = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await fetchFpsFilteredList(
        filtersToRequest(getFilters(), getSortParameter(), pager)
      );
      setFpses(res);
      setError(null);
    } catch (err) {
      setFpses(null);
      setError(err);
    }
    setLoading(false);
  };

  const debounceLoad = _debounce(loadFpsList, DEFAULT_FILTER_DEBOUNCE);

  /**
   * Charge la page suivante
   */
  const loadMoreRows = async (): Promise<void> => {
    const currentFpses = getFpses();
    // Check si nous devons envoyer la requête ou si nous sommes au bout
    if (
      !!currentFpses &&
      currentPage + 1 > currentFpses.totalHits / MAX_RECORDS
    ) {
      return;
    }

    setLoading(true);
    currentPage += 1;
    pager = { ...pager, page: currentPage };
    try {
      const res = await fetchFpsFilteredList(
        filtersToRequest(getFilters(), getSortParameter(), pager)
      );

      if (currentFpses && currentFpses.fpses && res.fpses) {
        const newFpsList = currentFpses.fpses.concat(res.fpses);
        setFpses({ ...currentFpses, fpses: newFpsList });
      } else {
        setFpses(res);
      }
    } catch (err) {
      // do nothing
    }
    setLoading(false);
  };

  /**
   * Maj les filtrers et recharge la liste avec les nouveaux filtres
   */
  const updateFilters = async (filters: FpsSearchCriterias) => {
    setFilters(filters);
    currentPage = INITIAL_PAGE;
    pager = { page: INITIAL_PAGE, maxRecords: MAX_RECORDS };

    await debounceLoad();
  };

  /**
   * Change le sens de tri d'un champ
   */
  const updateSortParameter = async (sortField: number): Promise<void> => {
    setSortParameter({
      sortField,
      increasingOrder:
        // si le tri est sur le même champ, on inverse, sinon on inverse le nouveau
        sortField === getSortParameter().sortField
          ? !getSortParameter().increasingOrder
          : !DEFAULT_SORT,
    });
    currentPage = INITIAL_PAGE;
    pager.page = INITIAL_PAGE;

    await debounceLoad();
  };

  /**
   * Reset la pagination et recharge la liste des FPS
   */
  const reload = async (): Promise<void> => {
    currentPage = INITIAL_PAGE;
    pager = { page: INITIAL_PAGE, maxRecords: MAX_RECORDS };

    await loadFpsList();
  };

  /**
   * Init le service avec le filtre plaque si passé en query param
   */
  const init = async (locationSearch: string | null): Promise<void> => {
    currentPage = INITIAL_PAGE;
    pager = { page: INITIAL_PAGE, maxRecords: MAX_RECORDS };

    // Si présence d'une plaque dans les param on réécrit les filters
    if (locationSearch) {
      loadQueryParamFilters(locationSearch, ({ plate }) =>
        setFilters(initialFilters(plate))
      );
    }

    await loadFpsList();
  };

  return {
    init,
    getFpses,
    watchFpses,
    getError,
    watchError,
    isLoading,
    watchLoading,
    reload,
    loadMoreRows,
    reportError,
    updateSortParameter,
    getSortParameter,
    watchSortParameter,
    getFilters,
    watchFilters,
    updateFilters,
  };
};

export default FpsListService;
