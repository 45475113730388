import _debounce from 'lodash.debounce';

import { Setter, WatchFunctionType } from '@cvfm-front/commons-types';
import { Watcher } from '@cvfm-front/commons-utils';
import { EligibilityFilterParam, Pager } from '@cvfm-front/tefps-types';
import { SortParameters } from 'api/commonTypes';
import { searchEligibilities } from 'api/eligibility';
import { EligibilitySearchResultDTO } from 'api/eligibility/type';
import { DEFAULT_FILTER_DEBOUNCE, MAX_RECORDS } from 'commons/const';
import loadQueryParamFilters from 'commons/helpers/loadQueryParamFilters';
import { initialFilters } from 'tefps/Eligibility/List/duck';
import { EligibilitySearchCriteria } from 'tefps/Eligibility/List/types';
import { filtersToRequest } from 'tefps/Eligibility/List/utils';

export interface EligibilityRightServiceFactory {
  (): EligibilityRightServiceInterface;
}

const initialPage = 0;
const DEFAULT_SORT = false;

export interface EligibilityRightServiceInterface {
  init: () => Promise<void>;
  getEligibilityRights: (
    startIndex?: number
  ) => EligibilitySearchResultDTO | null;
  watchEligibilityRights: WatchFunctionType<EligibilitySearchResultDTO | null>;
  applyEligibilityRightsChanges: (
    eligibilityRights: EligibilitySearchResultDTO
  ) => void;
  searchNextPage: () => Promise<void>;
  updateSortParameter: (sortField: number) => Promise<void>;
  getSortField: () => SortParameters;
  watchSortField: WatchFunctionType<SortParameters>;
  getFormattedFilters: () => EligibilityFilterParam;
  setFilter: Setter<EligibilitySearchCriteria>;
  watchFilter: WatchFunctionType<EligibilitySearchCriteria>;
  updateFilter: (filters: EligibilitySearchCriteria) => Promise<void>;
  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;
}

const EligibilityRightService: EligibilityRightServiceFactory = () => {
  const {
    getValue: getEligibilityRights,
    setValue: setEligibilityRights,
    watchValue: watchEligibilityRights,
  } = Watcher<EligibilitySearchResultDTO | null>(null);

  const {
    getValue: getFilter,
    setValue: setFilter,
    watchValue: watchFilter,
  } = Watcher<EligibilitySearchCriteria>(initialFilters());

  const {
    getValue: getSortField,
    setValue: setSortField,
    watchValue: watchSortField,
  } = Watcher<SortParameters>({
    sortField: 3,
    increasingOrder: false,
  });

  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  let currentPage = initialPage;
  let pager: Pager = {
    page: currentPage,
    maxRecords: MAX_RECORDS,
  };

  const fetchEligibilityRights = async () => {
    setLoading(true);
    const eligibilities = await searchEligibilities(
      filtersToRequest(getFilter(), getSortField(), pager)
    );
    setEligibilityRights(eligibilities);
    setLoading(false);
  };

  const debounceLoad = _debounce(
    fetchEligibilityRights,
    DEFAULT_FILTER_DEBOUNCE
  );

  const updateFilter = async (
    filters: EligibilitySearchCriteria
  ): Promise<void> => {
    setFilter(filters);
    currentPage = initialPage;
    pager = { ...pager, page: currentPage };

    await debounceLoad();
  };

  const getFormattedFilters = (): EligibilityFilterParam => {
    return filtersToRequest(getFilter(), getSortField(), pager);
  };

  const searchNextPage = async (): Promise<void> => {
    currentPage += 1;
    pager = { ...pager, page: currentPage };
    const previousEligibilities = getEligibilityRights();
    if (
      !!previousEligibilities &&
      currentPage > previousEligibilities.totalHits / MAX_RECORDS - 1
    ) {
      return;
    }

    setLoading(true);
    const newEligibilities = await searchEligibilities(
      filtersToRequest(getFilter(), getSortField(), pager)
    );
    if (previousEligibilities) {
      const newEligibilityResult = previousEligibilities.results.concat(
        newEligibilities.results
      );
      setEligibilityRights({
        ...previousEligibilities,
        results: newEligibilityResult,
      });
    } else {
      setEligibilityRights(newEligibilities);
    }
    setLoading(false);
  };

  const updateSortParameter = async (sortField: number): Promise<void> => {
    setSortField({
      sortField,
      increasingOrder:
        // si le tri est sur le même champ, on inverse, sinon on inverse le nouveau
        sortField === getSortField().sortField
          ? !getSortField().increasingOrder
          : !DEFAULT_SORT,
    });
    currentPage = initialPage;
    pager.page = initialPage;

    await debounceLoad();
  };

  const init = async (): Promise<void> => {
    currentPage = initialPage;
    pager.page = initialPage;
    const searchParameter = window.location.href.split('?');
    if (searchParameter.length === 2) {
      loadQueryParamFilters(
        searchParameter[1],
        (filters: { plate: string }) => {
          const { plate } = filters;
          const filter: EligibilitySearchCriteria = {
            ...getFilter(),
            plates: plate,
          };
          void updateFilter(filter);
        }
      );
    }

    await fetchEligibilityRights();
  };

  const applyEligibilityRightsChanges = (
    eligibilityRights: EligibilitySearchResultDTO
  ): void => {
    setEligibilityRights(eligibilityRights);
  };

  return {
    init,
    getEligibilityRights,
    watchEligibilityRights,
    applyEligibilityRightsChanges,
    searchNextPage,
    updateSortParameter,
    getSortField,
    watchSortField,
    getFormattedFilters,
    watchFilter,
    setFilter,
    updateFilter,
    setSortField,
    isLoading,
    watchLoading,
  };
};

export default EligibilityRightService;
