import { ValidatorResult } from '@cvfm-front/commons-types';

const { _t } = window.loadTranslations(__filename);

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

export function validateRequired(value: string | undefined): ValidatorResult {
  if (!value) {
    return {
      type: 'ERROR',
      message: _t('feedback.error.required'),
    };
  }
  return { type: 'SUCCESS' };
}

export function validateEmail(value: string | undefined): ValidatorResult {
  if (!value || !EMAIL_REGEX.test(value)) {
    return {
      type: 'ERROR',
      message: _t('feedback.error.emailFormat'),
    };
  }
  return { type: 'SUCCESS' };
}
