import { LocalizedAddress } from '@cvfm-front/tefps-types';
import { apiGet } from 'api/helpers';

import {
  MapId,
  DrawingType,
  MapServiceInterface,
  MarkerWrapper,
} from './MapService';

export interface AddressMapServiceFactory {
  (mapService: MapServiceInterface): AddressMapServiceInterface;
}

export interface AddressMapServiceInterface {
  fetchInsideBoundingBox: (
    bb: [number, number, number, number]
  ) => Promise<LocalizedAddress[]>;
  setupAutoRefresh: (mapId: MapId) => void;
  cancelAutoRefresh: (mapId: MapId) => void;
}

const ROOT_API = '/api/proxy/geocoder/api/v0/address';

const AddressMapService: AddressMapServiceFactory = mapService => {
  let currentCenter = '';
  let currentInterval: NodeJS.Timeout | null = null;
  let addresses: LocalizedAddress[] = [];
  let currentZoom = 0;

  const fetchInsideBoundingBox: AddressMapServiceInterface['fetchInsideBoundingBox'] = boundingBox => {
    const params = new URLSearchParams({
      top: boundingBox[0].toString(),
      left: boundingBox[1].toString(),
      bottom: boundingBox[2].toString(),
      right: boundingBox[3].toString(),
    });
    return apiGet<LocalizedAddress[]>(
      `${ROOT_API}/{cityId}/insideBoundingBox?${params}`
    );
  };

  const drawAddresses = (mapId: MapId) => {
    const markersToAdd: MarkerWrapper[] = addresses.map(address => ({
      id: address.concatAddress,
      type: DrawingType.ADDRESS,
      point: {
        latitude: address.latitude,
        longitude: address.longitude,
      },
      title: address.concatAddress,
      symbol: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 5,
        fillColor: '#660099',
        strokeColor: '#660099',
        fillOpacity: 0.2,
        strokeWeight: 1,
      },
    }));
    mapService.get(mapId)?.clear?.drawingType(DrawingType.ADDRESS);
    mapService.get(mapId)?.markers?.add(markersToAdd);
  };

  const setupAutoRefresh = (mapId: MapId) => {
    if (currentInterval !== null) {
      return;
    }
    currentInterval = setInterval(() => {
      const map = mapService.get(mapId);
      if (map === undefined) {
        return;
      }
      const mapCenter = map.center.get();
      const newCenter = JSON.stringify(mapCenter);
      const mapZoom = map.zoom.get();

      if (mapZoom <= 17) {
        map.clear.drawingType(DrawingType.ADDRESS);
        return;
      }

      if (
        (mapCenter.latitude === 0 && mapCenter.longitude === 0) ||
        (newCenter === currentCenter && mapZoom === currentZoom)
      ) {
        return;
      }
      currentCenter = newCenter;
      currentZoom = mapZoom;

      const boundingBox = map.viewPort.get();
      void fetchInsideBoundingBox(boundingBox).then(response => {
        addresses = response;
        drawAddresses(mapId);
      });
    }, 1000);
  };

  const cancelAutoRefresh = () => {
    if (currentInterval !== null) {
      clearInterval(currentInterval);
      currentInterval = null;
      currentCenter = '';
    }
  };

  return {
    fetchInsideBoundingBox,
    setupAutoRefresh,
    cancelAutoRefresh,
  };
};
export default AddressMapService;
