import React from 'react';

const { _tg } = window.loadTranslations(__filename);

const formatter = () => {
  // TODO ne devrait pas être dépendant de la langue
  if (window.i18next.language === 'rm') {
    return new window.Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 2,
    });
  }
  return new window.Intl.NumberFormat(window.i18next.language, {
    style: 'currency',
    currency: _tg('commons.Price.currency'),
    minimumFractionDigits: 2,
  });
};

export const formatCtsPrice = (price: number | null | undefined): string => {
  if (!price) return formatter().format(0);
  return formatter().format(price / 100);
};

/*
On pourra à terme faire une config dans le BDO. Mais le problème avec la Suisse est que la
 formatter formatte mal. Pour fr-CH il place la device à la fin alors qu'il devrait la placer
  au début
 */
export const formatSwissPrice = (price: number | null | undefined): string => {
  const swissFormatter = () => {
    return new window.Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: 'CHF',
      minimumFractionDigits: 2,
    });
  };
  if (!price) return swissFormatter().format(0);
  return swissFormatter().format(price / 100);
};

const Price = ({
  price,
  style,
}: {
  price: number | null | undefined;
  style?: React.CSSProperties;
}): JSX.Element => <span style={style}>{formatCtsPrice(price)}</span>;

export default Price;
