import _debounce from 'lodash.debounce';

import { searchParkingRights } from 'api/tickets';
import { filtersToRequest, initialFilters } from 'tefps/Tickets/List/utils';
import { Watcher } from '@cvfm-front/commons-utils';
import { SortParameters } from 'api/commonTypes';
import { ParkingRightSearchResultDTO } from 'api/tickets/types';
import { TicketsSearchCriteria } from 'tefps/Tickets/List/types';
import { WatchFunctionType } from '@cvfm-front/commons-types';

import loadQueryParamFilters from '../helpers/loadQueryParamFilters';
import { DEFAULT_FILTER_DEBOUNCE } from 'commons/const';

export interface TicketsListServiceFactory {
  (): TicketsListServiceInterface;
}

export interface TicketsListServiceInterface {
  init: (locationSearch: string | null) => Promise<void>;
  reload: () => Promise<void>;

  isLoading: () => boolean;
  watchLoading: WatchFunctionType<boolean>;

  loadMoreRows: () => Promise<void>;

  getError: () => Error | null;
  watchError: WatchFunctionType<Error | null>;

  getTickets: () => ParkingRightSearchResultDTO | null;
  watchTickets: WatchFunctionType<ParkingRightSearchResultDTO | null>;

  updateSortParameter: (sortField: number) => Promise<void>;
  getSortParameter: () => SortParameters;
  watchSortParameter: WatchFunctionType<SortParameters>;

  getFilters: () => TicketsSearchCriteria;
  watchFilters: WatchFunctionType<TicketsSearchCriteria>;
  updateFilters: (filters: TicketsSearchCriteria) => Promise<void>;
}

const MAX_RECORDS = 20;
const INITIAL_PAGE = 0;
const DEFAULT_SORT = false;

const TicketsListService: TicketsListServiceFactory = () => {
  const {
    getValue: getTickets,
    setValue: setTickets,
    watchValue: watchTickets,
  } = Watcher<ParkingRightSearchResultDTO | null>(null);

  const {
    getValue: getFilters,
    setValue: setFilters,
    watchValue: watchFilters,
  } = Watcher<TicketsSearchCriteria>(initialFilters());

  const {
    getValue: getError,
    setValue: setError,
    watchValue: watchError,
  } = Watcher<Error | null>(null);

  const {
    getValue: getSortParameter,
    setValue: setSortParameter,
    watchValue: watchSortParameter,
  } = Watcher<SortParameters>({
    sortField: 2,
    increasingOrder: DEFAULT_SORT,
  });

  const {
    getValue: isLoading,
    setValue: setLoading,
    watchValue: watchLoading,
  } = Watcher<boolean>(false);

  let currentPage = INITIAL_PAGE;

  /**
   * Charge des tickets, gère la pagination aussi
   */
  const fetchTickets = async (loadMore?: boolean) => {
    const currentTickets = getTickets();

    if (loadMore) {
      // Vérif si nous pouvons charger plus
      if (
        !!currentTickets &&
        currentPage + 1 > currentTickets.totalHits / MAX_RECORDS
      ) {
        return;
      }

      // Incrément de la page si on demande la suivante
      currentPage += 1;
    }

    setLoading(true);
    try {
      const res = await searchParkingRights(
        filtersToRequest(getFilters(), getSortParameter(), {
          page: currentPage,
          maxRecords: MAX_RECORDS,
        })
      );

      if (loadMore && currentTickets) {
        const newPaymentList = currentTickets.parkingRights.concat(
          res.parkingRights
        );
        setTickets({
          ...currentTickets,
          parkingRights: newPaymentList,
        });
        setError(null);
      } else {
        setTickets(res);
        setError(null);
      }
    } catch (err) {
      setError(err);
      setTickets(null);
    }
    setLoading(false);
  };

  const debounceLoad = _debounce(fetchTickets, DEFAULT_FILTER_DEBOUNCE);
  const loadMoreRows = async () => fetchTickets(true);
  const reload = async () => {
    currentPage = INITIAL_PAGE;

    await fetchTickets();
  };

  /**
   * Maj les filtrers et recharge la liste avec les nouveaux filtres
   */
  const updateFilters = async (filters: TicketsSearchCriteria) => {
    setFilters(filters);
    currentPage = INITIAL_PAGE;

    await debounceLoad();
  };

  /**
   * Change le sens de tri d'un champ
   */
  const updateSortParameter = async (sortField: number): Promise<void> => {
    setSortParameter({
      sortField,
      increasingOrder:
        // si le tri est sur le même champ, on inverse, sinon on inverse le nouveau
        sortField === getSortParameter().sortField
          ? !getSortParameter().increasingOrder
          : !DEFAULT_SORT,
    });

    await reload();
  };

  const init = async (locationSearch: string | null) => {
    // Si présence d'une plaque dans les param on réécrit les filters
    if (locationSearch) {
      loadQueryParamFilters(locationSearch, ({ plate }) =>
        setFilters(initialFilters(plate))
      );
    }

    await reload();
  };

  return {
    init,
    reload,
    loadMoreRows,
    isLoading,
    watchLoading,
    getTickets,
    watchTickets,
    getError,
    watchError,
    updateSortParameter,
    watchSortParameter,
    getSortParameter,
    getFilters,
    watchFilters,
    updateFilters,
  };
};

export default TicketsListService;
